<template>
  <div class="info">
    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->

      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>天翼数智科技（北京）有限公司</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <div class="i2-con">
          <!-- <p class="p-info">
            天翼数科拥有六大手段和一套安全机制，帮助企业构建完整的安全体系通过实现隐私安全、反诈骗、风控的闭环，助力合作伙伴构建安全体系，驱动业务升级。
          </p> -->

          <p>联系电话：021- 58520411</p>
          <!-- <p>网站邮箱：zhanglingyu@quantumgalaxy.cn</p> -->
          <p>网站域名：www.tydt.com</p>
          <p>
            网站备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"
              >******</a
            >
          </p>
          <p>联系地址：北京市西城区复兴门南大街乙2号天银大厦A东座5F</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "天翼数科 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #f0f0f0;

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;
    height: 497px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 100%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;
        .p-info {
          line-height: 55px;
        }
        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
          text-indent: 30px;
          line-height: 41px;
          a {
            color: #666;
          }
        }
      }
    }
  }
}
</style>
