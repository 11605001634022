<template>
  <div class="info">
    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->

      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>北京要开心科技有限公司</h3>
        <!-- 下环线 -->
        <div class="bink"></div>


        <div class="i2-con">

          <p class="p-info">
            北京要开心科技有限公司成立于2023年03月01日，注册地位于北京市丰台区城南嘉园益城园16号楼12层1-1205，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；计算机软硬件及辅助设备批发；计算机软硬件及辅助设备零售；数据处理服务；专业设计服务；会议及展览服务；广告制作；广告设计、代理；广告发布；企业管理咨询；文艺创作；组织文化艺术交流活动；市场调查（不含涉外调查）；
          </p>


          <p class="p-info">
            软件开发。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：第二类增值电信业务；互联网信息服务；网络文化经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>

          <button @click="downloadMp3">下载MP3文件</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京要开心科技有限公司 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },

    downloadMp3() {
      const url = 'http://www.power-of-light.cn/mxw/爱的对味-匡雯霁.mp3'; // MP3文件的URL地址
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.mp3'; // 下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.info {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #f0f0f0;

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;
    height: 497px;

    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;

        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;

          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }

    // 右侧
    .info-2 {
      width: 100%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;

      h3 {
        font-size: 24px;
      }

      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;

        .p-info {
          line-height: 55px;
        }

        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
          text-indent: 30px;
          line-height: 41px;

          a {
            color: #666;
          }
        }
      }
    }
  }
}</style>
