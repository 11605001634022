<template>
  <div class="index">
     <div class="banner">
        <img src="../../public/imgs/banner.jpg" alt="">
      </div>
    <div class="container">
     
      <!-- 区块1 -->
      <div class="a1">
        <!-- 右侧 -->
        <div class="a1-r">
          <!-- 标题 -->
          <div class="r-title">
            <h2>今日要闻 <span>TODAY NEWS</span></h2>
          </div>

          <!-- 循环项 -->
          <div class="r-item" v-for="(item, index) in ac1" :key="index" @click="gopay()">
            <h3>
              {{ item.title }}
            </h3>
            <p>
              {{ item.jj }}
            </p>
          </div>
        </div>
        <!-- 左侧 -->
        <div class="a1-l">
          <!-- 上层大图 -->
          <img src="../../public/imgs/n1.jpg" />

          <div class="l-info">
            <!-- 循环项 *2 -->
            <div
              class="info-list"
              v-for="(item, index) in ac"
              :key="index"
              @click="gopay(item.id)"
            >
              <img :src="item.img" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 资讯区块2 -->
      <div class="ac2">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 资讯区块1 -->
          <div class="ac22">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>收费资讯</h2>
              <h4></h4>
            </div>

            <!-- 循环项 -->
            <div
              class="a1-item"
              v-for="(item, index) in this.d1"
              :key="index"
              @click="gopay(item.id)"
            >
              <!-- 标题 -->
              <div class="title">
                {{ item.title }}
              </div>
              <!-- 中层 -->
              <div class="item-con">
                <img :src="item.img" alt="" />
                <div class="item-jj">
                  {{ item.jj }}
                </div>
              </div>
              <!-- 信息层 -->
              <div class="item-info">
                <!-- 左侧 -->
                <div class="item-l">
                  <span>{{ item.author }}</span>
                  <span> ({{ item.sub }})阅读 </span>
                </div>
                <!-- 右侧 -->
                <div class="item-r">收费阅读:1元/篇</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="width: 275px" src="../../public/imgs/pay.png" />
        <h2>扫码付费阅读：1元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import navigation from "./../components/navigation";
import Modal from "./../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      href: "index.html",
      ac1: [],
      d1: [],
      ac3: [1, 2, 1, 1, 11, 1, 1],
      ac: [1, 2, 3],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "全宁官方网站";
  },
  methods: {
    init() {
      // this.axios.get("./../../public/mock/ac.json").then((res) => {
      this.axios.get("./mock/ac.json").then((res) => {
        const data = res.data;
        this.ac = data.slice(0, 3);
        this.ac1 = data.slice(5, 7);
        this.d1 = data.slice(0, 20);
        this.ac2 = data.slice(5, 12);
        this.mAc = data.slice(4, 10);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // alert("资讯每条1元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费1元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    // 登录跳转
    goLogin() {
      alert("资讯每篇1元，点击确定购买");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  background: #f5f7fa;
  .banner {
    width: 100%;
    margin-bottom: 50px;
    img{
      width: 100%;
    }
  }
  .container {
    // 区块1
    .a1 {
      width: 100%;
      height: 500px;
      @include flex();
      margin: 30px 0;
      background: #fff;
      // 左侧
      .a1-l {
        width: 70%;
        height: 100%;
        img {
          width: 100%;
          height: 300px;
        }

        // 下层
        .l-info {
          width: 100%;
          height: 200px;
          @include flex();
          padding: 10px 0;
          box-sizing: border-box;

          // 循环项
          .info-list {
            width: 273px;
            height: 100%;
            position: relative;
            cursor: pointer;
            margin: 0 2px;
            &:hover {
              p {
                transform: translateY(-15px);
              }
            }
            img {
              width: 100%;
              height: 100%;
            }

            p {
              width: 203px;
              height: 50px;
              position: absolute;
              top: 36%;
              left: 11%;
              background-color: rgba(255, 255, 255, 0.8);
              text-align: center;
              line-height: 50px;
              font-size: 14px;
              transition: all 0.3s;
              font-weight: bold;
              color: #333;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }
        }
      }
      // 右侧
      .a1-r {
        width: 30%;
        height: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        margin-right: 40px;
        // 标题
        .r-title {
          width: 100%;
          height: 30px;
          border-bottom: 1px solid $colorZ;
          padding-left: 10px;
          line-height: 30px;
          box-sizing: border-box;
          h2 {
            font-size: 20px;
            color: #333;
            span {
              font-size: 12px;
              color: #888;
              margin-left: 5px;
            }
          }
        }

        // 循环项
        .r-item {
          width: 100%;
          height: 215px;
          margin: 20px 0;
          padding: 10px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            h3 {
              color: $colorZ;
            }
          }
          h3 {
            width: 100%;
            font-size: 16px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          p {
            font-size: 12px;
            color: #888;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            line-height: 40px;
            margin-top: 15px;
          }
        }
      }
    }

    // 资讯区块2
    .ac2 {
      width: 100%;
      // height: 800px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;
        // background-color: #ff6600;
        // margin-left: 30px;
        //   资讯区块1
        .ac22 {
          width: 100%;
          // height: 600px;

          margin-top: 30px;
          // 标题
          .a1-title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            h2 {
              color: #333;
            }
          }

          // 循环项
          .a1-item {
            width: 100%;
            height: 200px;
            padding: 5px;
            box-sizing: border-box;
            margin: 30px 0;
            transition: all 0.2s;
            border: 2px solid #fff;

            padding: 10px 20px;
            box-sizing: border-box;
            &:hover {
              // box-shadow: 0px 0px 8px #888;
              // transform: translateY(-10px);
              background-color: #fff;
              border: 2px dashed #ccc;
              .title {
                color: $colorZ;
              }
            }
            //   标题
            .title {
              width: 100%;
              font-size: 14px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                color: $colorZ;
              }
            }
            //   中层
            .item-con {
              width: 100%;
              height: 120px;
              margin: 10px 0;
              // background: #ff5544;
              display: flex;
              align-items: center;
              // 图品
              img {
                width: 200px;
                height: 100%;
              }
              .item-jj {
                margin-left: 20px;
                width: 60%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 12px;
                color: #888;
                line-height: 30px;
              }
            }
            //   信息层
            .item-info {
              height: 30px;
              width: 100%;
              // background-color: #451215;

              // @include flex();
              display: flex;
              justify-content: space-between;
              align-items: center;
              .item-l {
                span {
                  color: #888;
                  font-size: 12px;
                  margin-right: 30px;
                  i {
                    font-size: 14px;
                    margin-right: 5px;
                  }
                }
              }
              .item-r {
                font-size: 12px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}
</style>
