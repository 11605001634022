<template>
  <div class="nav-footer">
    <div class="container">
      <!-- 右侧 -->
      <div class="r">

        <p>公司名称：北京启明晟成网络科技有限公司</p>
        <p>公司域名：www.5iyaoxin.com</p>
        <p>公司座机：010-84249214</p>
        <p>公司地址：北京市海淀区花园路B3号3幢10层</p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备11033656号-2</a>
        </p>
      </div>
      <!-- 左侧 -->
      <div class="l">
        <!-- <p>域名：hanyuhl.com</p>
        <p>邮箱：taoshengru@163.com</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  width: 100%;
  // height: 95px;
  background: #444;

  // margin-top: 30px;
  .container {
    height: 100%;
    @include flex();

    .l {
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;
        text-align: right;
      }
    }

    .r {
      text-align: left;

      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;

        i {
          margin: 0 5px;
        }

        a {
          color: #fafafa;
        }
      }
    }
  }
}
</style>
